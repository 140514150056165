import { motion } from 'framer-motion'

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
}
function CategoryItem (props) {
  const Image = require('../../assets/img/' + props.image)
  const dyClass = `${props.direction} card relative  col-span-1  md:col-span-1 lg:col-span-1  xl:col-span-1  cursor-pointer  ${props.color} rounded-[11px] h-[140px] mb-3`
  return (
    
    <motion.div className={dyClass} variants={item}  onClick={props.onClick }>
      <div className='grid grid-cols-2  h-full'>
        <h2 className='text-white my-auto col-span-1'>{props.title}</h2>
      </div>
      <img
        src={Image}
        className={
          props.imageClass + ' col-span-1 absolute bottom-0 top-0  h-[140px]'
        }
        loading="lazy" 
        alt={props.title}
      />
    </motion.div>

  )
}
export default CategoryItem
