import MenuItem from './MenuItem'
import { delay, motion } from 'framer-motion'
const containerM = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.1,
    }
  }
}

const item = {
  hidden: { y: 10, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
}
function MenuList ({ title, items ,enable_price , preview_type}) {
  return (
    <motion.div
      className='containerM  grid grid-cols-12 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-2 '
      variants={containerM}
      initial='hidden'
      animate='visible'
    >
      <div className='col-span-12 md:col-span-2 lg:col-span-3  xl:col-span-4 sticky top-0 bg-[#FFFFF0] '
      variants={item}>
        <h1 className='text-pesto-golden font-bold text-[20px] mb-4 mt-[10px] '>
          {title}
        </h1>
       
      </div>
      {Object.keys(items).map(key =>
        items[key].details.ar ? (
            <MenuItem
              key={key}
              title={items[key].title.ar}
              image={items[key].image}
              type={preview_type}
              desc={items[key].details.ar}
              price={enable_price ? items[key].price :null }
            />
        ) : (
            <MenuItem 
            type={preview_type}
            price={enable_price ? items[key].price :null }

             key={key} title={items[key].title.ar} image={items[key].image} />
        )
      )}
    </motion.div>
  )
}
export default MenuList
