import MenuList from './MenuList'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'

// import required modules
import { Navigation } from 'swiper/modules'
import { useEffect } from 'react'

function MenuLists ({ categories, selected_index , enable_price }) {
  const sort = items => {
    items.sort((item1, item2) => {
      if (!item1.details.ar) {
        return 1
      }
      if (item1.details.ar) {
        return -1
      }
      return 0
    })
    return items
  }
  const ChangeSlide = ({ position }) => {
    const swiper = useSwiper()

    useEffect(() => {
      if (swiper) {
        swiper.slideTo(position)
      }
    }, [swiper, position])
    return null
  }
  return (
    <>
      {selected_index != null ? (
        <Swiper
          space-between={5}
          navigation={true}
          modules={[Navigation]}
          className='mySwiper force-rtl height-inherit'
          initialSlide={selected_index}
        >
          {/* <ChangeSlide position={selected_index} /> */}
          {categories.map((category, index) => (
            <SwiperSlide className='h-[84vh] md:h-[86vh]  xl:h-[82vh] max-h-[86vh] relative' key={index}>
              <MenuList
                title={category.title.ar}
                items={category.items}
                enable_price={enable_price}
                preview_type={category.preview_type}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <></>
      )}
    </>
  )
}
export default MenuLists
