import CategoryItem from './CategoryItem'
import { motion } from 'framer-motion'
import MenuLists from './MenuLists'
import { useState } from 'react'
import menuData from '../../assets/menu.json'

const containerM = {
  hidden: {
    opacity: 1,
    scale: 0,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
}
function CategoriesList () {
  const [selected, setSelected] = useState(null)
  const changeSelected = index => {
    setSelected(index)
  }
  return (
    <div className='height-inherit'>
      <motion.div
        variants={containerM}
        initial='hidden'
        animate='visible'
        className={
          'containerM height-inherit grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 force-rtl  '+
          (selected != null ? ' hide-animation cat-item' : 'my-4 cat-item md:gap-3')
        }
      >
        {menuData.menu.map((category, index) => (
          <div
            key={index}
          >
            <CategoryItem
              title={category.title.ar}
              image={category.image}
              color={index % 2 === 0 ? 'bg-pesto-golden' : 'bg-pesto-purple'}
              direction={index % 2 === 0 ? 'force-ltr' : 'force-rtl'}
              imageClass={index % 2 === 0 ? 'right-0' : 'left-0'}
              onClick={() => changeSelected(index)}
            />
          </div>
        ))}
      </motion.div>
      <MenuLists categories={menuData.menu} selected_index={selected} enable_price={menuData.enable_price}/>
    </div>
  )
}
export default CategoriesList
