import logo from "../../assets/img/logo.svg";
function Header() {
  return (
    <nav className="w-full h-full grid grid-cols-12 bg-[#FFFFF0] border-gray-200 dark:bg-gray-900">
      <div className="col-span-10 col-start-2 w-full flex flex-wrap items-center justify-between mx-auto">
        <a href="/" className="mx-auto flex items-center space-x-3 rtl:space-x-reverse h-[9vh] md:h-[9vh] xl:h-[12vh]">
          <img src={logo} className="h-full max-w-[80%] mx-auto py-3" alt="Pesto logo" loading="lazy" />
        </a>
        {/* <button
          type="button"
          className="inline-flex items-center py-2 w-10 h-10 justify-end text-sm text-gray-500 rounded-lg  focus:outline-none  dark:text-gray-400 "
        >
          EN
        </button> */}
      
      </div>
    </nav>
  );
}

export default Header;
