import { motion } from 'framer-motion'

const item = {
  hidden: { y: 10, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
}
function MenuItem (props) {
  const Image = require('../../assets/img/menu/' + props.image)
  const dyClass = `card px-1  bg-cover mb-3 ${
    props?.type == 'full' ? 'col-span-12' : 'col-span-6'
  }  md:col-span-1`
  return (
    <motion.div className={dyClass} variants={item}>
      <div className='h-full text-right'>
        <div
          className='bg-cover bg-no-repeat	bg-center mb-2 rounded-[11px] h-[190px]'
          style={{
            backgroundImage: `url(${Image})`
          }}
        ></div>
        <div
          className={
            'text w-full mb-auto' +
            (props?.type == 'full'
              ? 'text-right border-b md:border-none border-dotted border-pesto-golden pb-3 lg:text-right'
              : 'text-right md:text-right lg:text-right')
          }
        >
          <div className='flex flex-row justify-between items-center'>
          <div className='flex flex-col ml-3'>
            <h2 className='font-semibold'>{props.title}</h2>
            <p className='mb-0 text-[12px] leading-snug'>{props.desc} </p>
          </div>
          {props.price ? (
            <p className='mb-0 text-[16px] leading-snug text-pesto-golden font-bold text-nowrap force-ltr'>{props.price} SP</p>
          ) : (
            ''
          )}
</div>
          
        </div>
      </div>
    </motion.div>
  )
}
export default MenuItem
