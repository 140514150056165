import './App.css'
import MenuList from './components/Menu/MenuList'
import CategoriesList from './components/Menu/CategoriesList'
import Header from './components/Layout/Header'
import Footer from './components/Layout/Footer'
import { useState } from 'react'

function App () {
  return (
    <div className='App bg-[#FFFFF0] h-screen flex flex-col'>
      <header className='grid grid-cols-12 text-center sticky top-0 z-10 '>
        <div className='col-span-12 px-0  drop-shadow-sm border-b'>
          <Header />
        </div>
      </header>
      <main className='grid grid-cols-12 text-center h-[84vh] md:h-[86vh]  xl:h-[82vh]'>
        <div className='col-span-10 col-start-2 px-0 height-inherit'>
          <CategoriesList/>
        </div>
      </main>
            <Footer/>
    </div>
  )
}

export default App
